<template>
  <!-- eslint-disable max-len -->
  <transition
    appear
    enter-active-class="transform ease-out duration-300 transition"
    enter-class="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="opacity-100 translate-y-0 sm:translate-x-0"
    leave-active-class="transition ease-in duration-100"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div class="flex fixed inset-0 z-30 justify-center sm:justify-end items-end sm:items-start p-6 pointer-events-none">
      <div class="w-full max-w-sm bg-white rounded-lg shadow-lg pointer-events-auto">
        <div class="overflow-hidden rounded-lg shadow-xs">
          <div class="flex items-start p-4">
            <div class="flex-1">
              <p class="font-medium text-primary-400">
                <icon-alert
                  v-if="type === 'error'"
                  class="inline-block w-5 h-5 text-red-400"
                />
                {{ title }}
              </p>
              <p
                v-if="message"
                class="text-sm text-gray-500"
              >
                {{ message }}
              </p>
            </div>
            <div class="flex flex-shrink-0 ml-4">
              <button
                class="inline-flex text-gray-400 focus:text-gray-500 transition focus:outline-none"
                @click="$emit('close')"
              >
                <svg
                  class="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'notice',
    },
    duration: {
      type: Number,
      required: false,
      default: 5000,
    },
  },
  mounted() {
    if (this.duration) {
      setTimeout(() => {
        this.$emit('close');
      }, this.duration);
    }
  },
};
</script>
