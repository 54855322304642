import Vue from 'vue';

export default [
  {
    path: '/',
    name: 'calculator',
    // @TODO remove to enable calculator
    redirect: { name: 'login' },
    meta: {
      auth: false,
      showChat: true,
    },
    component: () => import('@/views/Calculator.vue'),
  },
  {
    path: '/quotation/:token',
    name: 'quotation',
    meta: {
      auth: false,
    },
    component: () => import('@/views/Quotation.vue'),
  },
  {
    path: '/shipment/:token',
    name: 'shipment',
    component: () => import('@/views/Shipment.vue'),
    props: () => ({ action: 'register' }),
  },
  {
    path: '/shipment/tracking/:code',
    name: 'shipment-tracking',
    props: (route) => ({ action: 'login', trackingCode: route.params.code }),
    component: () => import('@/views/Shipment.vue'),
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      auth: false,
    },
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/tracking',
    name: 'tracking',
    meta: {
      auth: false,
    },
    component: () => import('@/views/Tracking.vue'),
  },
  {
    path: '/portal',
    name: 'portal',
    redirect: { name: 'portal-quotations' },
    meta: {
      auth: true,
    },
    props: {
      items: [
        'calculations',
        'quotations',
        { shipments: ['shipment-requests', 'shipments'] },
        'invoices',
        'tiers',
        'addresses',
        'users',
        'data',
      ],
    },
    component: () => import('@/components/Layout.vue'),
    beforeEnter(to, from, next) {
      if (Vue.prototype.$auth.check('access admin')) {
        next('/admin');
      } else {
        next();
      }
    },
    children: [
      {
        path: 'calculations',
        name: 'portal-calculations',
        meta: {
          search: true,
        },
        component: () => import('@/views/portal/Calculations.vue'),
      },
      {
        path: 'calculations/add',
        name: 'portal-calculations-add',
        component: () => import('@/views/portal/CalculationsForm.vue'),
        props: () => ({
          showActions: true,
        }),
      },
      {
        path: 'calculations/:id',
        name: 'portal-calculations-edit',
        component: () => import('@/views/portal/CalculationsForm.vue'),
        props: (route) => ({
          showActions: true,
          id: +route.params.id,
        }),
      },
      {
        path: 'calculations/:id/view',
        name: 'portal-calculations-show',
        component: () => import('@/views/portal/CalculationsShow.vue'),
        props: (route) => ({ id: +route.params.id }),
      },
      {
        path: 'quotations',
        name: 'portal-quotations',
        meta: {
          search: true,
        },
        component: () => import('@/views/portal/Quotations.vue'),
      },
      {
        path: 'quotations/add',
        name: 'portal-quotations-add',
        component: () => import('@/views/portal/QuotationsForm.vue'),
      },
      {
        path: 'quotations/:id',
        name: 'portal-quotations-edit',
        component: () => import('@/views/portal/QuotationsForm.vue'),
        props: (route) => ({ id: +route.params.id }),
      },
      {
        path: 'quotations/:id/view',
        name: 'portal-quotations-show',
        component: () => import('@/views/portal/QuotationsShow.vue'),
        props: (route) => ({ id: +route.params.id }),
      },
      {
        path: 'shipments',
        name: 'portal-shipments',
        meta: {
          search: true,
        },
        component: () => import('@/views/portal/Shipments.vue'),
      },
      {
        path: 'shipments/requests',
        name: 'portal-shipment-requests',
        meta: {
          search: true,
        },
        component: () => import('@/views/portal/ShipmentRequests.vue'),
      },
      {
        path: 'shipments/requests/add',
        name: 'portal-shipment-requests-add',
        component: () => import('@/views/portal/ShipmentRequestsForm.vue'),
      },
      {
        path: 'shipments/requests/:id',
        name: 'portal-shipment-requests-edit',
        component: () => import('@/views/portal/ShipmentRequestsForm.vue'),
        props: (route) => ({ id: +route.params.id }),
      },
      {
        path: 'shipments/requests/:id/view',
        name: 'portal-shipment-requests-show',
        component: () => import('@/views/portal/ShipmentRequestsShow.vue'),
        props: (route) => ({ id: +route.params.id }),
      },
      {
        path: 'shipments/:id',
        name: 'portal-shipments-show',
        component: () => import('@/views/portal/ShipmentsShow.vue'),
        props: (route) => ({ id: +route.params.id }),
      },
      {
        path: 'invoices',
        name: 'portal-invoices',
        meta: {
          search: true,
        },
        component: () => import('@/views/portal/Invoices.vue'),
      },
      {
        path: 'tiers',
        name: 'portal-tiers',
        meta: {
          search: true,
        },
        component: () => import('@/views/portal/Tiers.vue'),
      },
      {
        path: 'addresses',
        name: 'portal-addresses',
        meta: {
          search: true,
        },
        component: () => import('@/views/portal/Addresses.vue'),
      },
      {
        path: 'addresses/add',
        name: 'portal-addresses-add',
        component: () => import('@/views/portal/AddressesForm.vue'),
      },
      {
        path: 'addresses/:id',
        name: 'portal-addresses-edit',
        component: () => import('@/views/portal/AddressesForm.vue'),
        props: (route) => ({ id: +route.params.id }),
      },
      {
        path: 'account',
        name: 'portal-account',
        component: () => import('@/views/portal/UsersForm.vue'),
        props: () => {
          const user = Vue.auth.user();
          return {
            id: user.id,
            user,
            redirect: false,
          };
        },
      },
      {
        path: 'users',
        name: 'portal-users',
        meta: {
          search: true,
        },
        component: () => import('@/views/portal/Users.vue'),
      },
      {
        path: 'users/add',
        name: 'portal-users-add',
        component: () => import('@/views/portal/UsersForm.vue'),
      },
      {
        path: 'users/:user',
        name: 'portal-users-edit',
        component: () => import('@/views/portal/UsersForm.vue'),
        props: (route) => ({ id: +route.params.user }),
      },
      {
        path: 'data',
        name: 'portal-data',
        component: () => import('@/views/portal/Data.vue'),
      },
      {
        path: 'help',
        name: 'portal-help',
        component: () => import('@/views/portal/Help.vue'),
      },
      {
        path: '*',
        name: 'portal-notfound',
        component: () => import('@/views/NotFound.vue'),
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: { name: 'admin-calculations' },
    meta: {
      auth: 'access admin',
    },
    props: {
      items: [
        'tasks',
        'calculations',
        'quotations',
        'relations',
        'admin users',
        'settings',
      ],
    },
    component: () => import('@/components/Layout.vue'),
    children: [
      {
        path: 'tasks',
        name: 'admin-tasks',
        meta: {
          search: true,
        },
        component: () => import('@/views/admin/Tasks.vue'),
        props: (route) => ({
          id: +route.params.relation,
        }),
      },
      {
        path: 'calculations',
        name: 'admin-calculations',
        meta: {
          search: true,
        },
        component: () => import('@/views/admin/Calculations.vue'),
      },
      {
        path: 'calculations/:id',
        name: 'admin-calculations-show',
        component: () => import('@/views/admin/CalculationsShow.vue'),
        props: (route) => ({ id: +route.params.id }),
      },
      {
        path: 'calculations/add',
        name: 'admin-calculations-add',
        component: () => import('@/views/portal/CalculationsForm.vue'),
      },
      {
        path: 'quotations',
        name: 'admin-quotations',
        meta: {
          search: true,
        },
        component: () => import('@/views/admin/Quotations.vue'),
      },
      {
        path: 'quotations/:id',
        name: 'admin-quotations-show',
        component: () => import('@/views/admin/QuotationsShow.vue'),
        props: (route) => ({ id: +route.params.id }),
      },
      {
        path: 'quotations/add',
        name: 'admin-quotations-add',
        component: () => import('@/views/admin/QuotationsShow.vue'),
      },
      {
        path: 'relations',
        name: 'admin-relations',
        meta: {
          search: true,
        },
        component: () => import('@/views/admin/Relations.vue'),
      },
      {
        path: 'relations/add',
        name: 'admin-relations-add',
        component: () => import('@/views/admin/RelationsForm.vue'),
        props: () => ({ showHeader: true }),
      },
      {
        path: 'relations/:relation',
        name: 'admin-relations-show',
        component: () => import('@/views/admin/RelationsShow.vue'),
        redirect: { name: 'admin-relations-show-data' },
        props: (route) => ({ id: +route.params.relation }),
        children: [
          {
            path: 'data',
            name: 'admin-relations-show-data',
            component: () => import('@/views/admin/RelationsShowData.vue'),
          },
          {
            path: 'edit',
            name: 'admin-relations-edit-data',
            component: () => import('@/views/admin/RelationsForm.vue'),
          },
          {
            path: 'quotations',
            name: 'admin-relations-show-quotations',
            component: () => import('@/views/admin/RelationsShowQuotations.vue'),
            props: (route) => ({ id: +route.params.relation }),
          },
          {
            path: 'tasks',
            name: 'admin-relations-show-tasks',
            meta: {
              search: true,
            },
            component: () => import('@/views/admin/Tasks.vue'),
            props: (route) => ({ id: +route.params.relation }),
          },
          {
            path: 'addresses',
            name: 'admin-relations-show-addresses',
            component: () => import('@/views/admin/RelationsShowAddresses.vue'),
          },
          {
            path: 'addresses/add',
            name: 'admin-relations-show-addresses-add',
            component: () => import('@/views/admin/RelationsShowAddressesForm.vue'),
          },
          {
            path: 'addresses/:address',
            name: 'admin-relations-show-addresses-edit',
            component: () => import('@/views/admin/RelationsShowAddressesForm.vue'),
            props: (route) => ({ id: +route.params.address }),
          },
          {
            path: 'tiers',
            name: 'admin-relations-show-tiers',
            component: () => import('@/views/admin/RelationsShowTiers.vue'),
          },
          {
            path: 'tiers/add',
            name: 'admin-relations-show-tiers-add',
            component: () => import('@/views/admin/RelationsShowTiersForm.vue'),
          },
          {
            path: 'tiers/:tier',
            name: 'admin-relations-show-tiers-edit',
            component: () => import('@/views/admin/RelationsShowTiersForm.vue'),
            props: (route) => ({ id: +route.params.tier }),
          },
          {
            path: 'reports',
            name: 'admin-relations-show-reports',
            component: () => import('@/views/admin/RelationsShowReports.vue'),
          },
          {
            path: 'reports/add',
            name: 'admin-relations-show-reports-add',
            component: () => import('@/views/admin/RelationsShowReportsForm.vue'),
          },
          {
            path: 'reports/:report',
            name: 'admin-relations-show-reports-edit',
            component: () => import('@/views/admin/RelationsShowReportsForm.vue'),
            props: (route) => ({ id: +route.params.report }),
          },
          {
            path: 'users',
            name: 'admin-relations-show-users',
            component: () => import('@/views/admin/RelationsShowUsers.vue'),
          },
          {
            path: 'users/add',
            name: 'admin-relations-show-users-add',
            component: () => import('@/views/admin/RelationsShowUsersForm.vue'),
          },
          {
            path: 'users/:user',
            name: 'admin-relations-show-users-edit',
            component: () => import('@/views/admin/RelationsShowUsersForm.vue'),
            props: (route) => ({ id: +route.params.user }),
          },
          {
            path: 'remarks',
            name: 'admin-relations-show-remarks',
            component: () => import('@/views/admin/RelationsShowRemarks.vue'),
          },
          {
            path: 'settings',
            name: 'admin-relations-show-settings',
            component: () => import('@/views/admin/RelationsShowSettings.vue'),
            props: (route) => ({ id: +route.params.relation }),
          },
        ],
      },
      {
        path: 'account',
        name: 'admin-account',
        component: () => import('@/views/admin/UsersForm.vue'),
        props: () => {
          const user = Vue.auth.user();
          return {
            id: user.id,
            user,
            redirect: false,
          };
        },
      },
      {
        path: 'users',
        name: 'admin-users',
        meta: {
          search: true,
        },
        component: () => import('@/views/admin/Users.vue'),
      },
      {
        path: 'users/add',
        name: 'admin-users-add',
        component: () => import('@/views/admin/UsersForm.vue'),
      },
      {
        path: 'users/:user',
        name: 'admin-users-edit',
        component: () => import('@/views/admin/UsersForm.vue'),
        props: (route) => ({ id: +route.params.user }),
      },
      {
        path: 'settings',
        name: 'admin-settings',
        component: () => import('@/views/admin/Settings.vue'),
      },
      {
        path: '*',
        name: 'admin-notfound',
        component: () => import('@/views/NotFound.vue'),
      },
    ],
  },
  {
    path: '*',
    name: 'notfound',
    component: () => import('@/views/NotFound.vue'),
  },
];
