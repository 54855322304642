import Vue from 'vue';
import VueI18n from 'vue-i18n';
import EventBus from '@/eventBus';
import bundleLoader from '@/bundle-loader';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = bundleLoader.importTranslations();
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const messages = loadLocaleMessages();
const defaultLocale = 'nl';
const preferred = navigator.language.split('-')[0];
const locale = messages[preferred] !== undefined ? preferred : defaultLocale;

const i18n = new VueI18n({
  locale,
  messages,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
});

export function changeLocale(setting) {
  i18n.locale = setting;
  EventBus.$emit('locale', setting);
}

export default i18n;
