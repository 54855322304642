import Vue from 'vue';

import axios from '@/plugins/axios';
import i18n from '@/plugins/i18n';
import router from '@/plugins/router';
import '@/plugins/auth';
import '@/plugins/currency-filter';
import '@/plugins/date-fns';
import '@/plugins/nl2br';
import '@/plugins/redactor';

import '../css/styles.css';

import App from '@/App.vue';
import IconAddresses from '@/components/icons/IconAddresses.vue';
import IconAlert from '@/components/icons/IconAlert.vue';
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue';
import IconCalculations from '@/components/icons/IconCalculations.vue';
import IconCheckCircle from '@/components/icons/IconCheckCircle.vue';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import IconChevronLeft from '@/components/icons/IconChevronLeft.vue';
import IconChevronRight from '@/components/icons/IconChevronRight.vue';
import IconData from '@/components/icons/IconData.vue';
import IconDate from '@/components/icons/IconDate.vue';
import IconDownload from '@/components/icons/IconDownload.vue';
import IconInvoices from '@/components/icons/IconInvoices.vue';
import IconPlace from '@/components/icons/IconPlace.vue';
import IconQuotations from '@/components/icons/IconQuotations.vue';
import IconRelations from '@/components/icons/IconRelations.vue';
import IconReports from '@/components/icons/IconReports.vue';
import IconSettings from '@/components/icons/IconSettings.vue';
import IconShipments from '@/components/icons/IconShipments.vue';
import IconTasks from '@/components/icons/IconTasks.vue';
import IconTiers from '@/components/icons/IconTiers.vue';
import IconTime from '@/components/icons/IconTime.vue';
import IconTrash from '@/components/icons/IconTrash.vue';
import IconUsers from '@/components/icons/IconUsers.vue';

// Register icons globally
Vue.component('IconAddresses', IconAddresses);
Vue.component('IconAlert', IconAlert);
Vue.component('IconArrowLeft', IconArrowLeft);
Vue.component('IconCalculations', IconCalculations);
Vue.component('IconCheckCircle', IconCheckCircle);
Vue.component('IconChevronDown', IconChevronDown);
Vue.component('IconChevronLeft', IconChevronLeft);
Vue.component('IconChevronRight', IconChevronRight);
Vue.component('IconData', IconData);
Vue.component('IconDate', IconDate);
Vue.component('IconDownload', IconDownload);
Vue.component('IconInvoices', IconInvoices);
Vue.component('IconPlace', IconPlace);
Vue.component('IconQuotations', IconQuotations);
Vue.component('IconRelations', IconRelations);
Vue.component('IconReports', IconReports);
Vue.component('IconSettings', IconSettings);
Vue.component('IconShipments', IconShipments);
Vue.component('IconTasks', IconTasks);
Vue.component('IconTiers', IconTiers);
Vue.component('IconTime', IconTime);
Vue.component('IconTrash', IconTrash);
Vue.component('IconUsers', IconUsers);
Vue.component('IconAdminUsers', IconUsers);

Vue.config.productionTip = false;

// Adds locale header
axios.interceptors.request.use((config) => {
  config.headers['Accept-Language'] = i18n.locale; // eslint-disable-line no-param-reassign
  return config;
});

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');
