<template>
  <div>
    <router-view />

    <modal
      v-if="modal"
      v-bind="modal"
      @close="modal = false"
    />
    <notification
      v-if="notification"
      v-bind="notification"
      @close="notification = false"
    />
  </div>
</template>

<script>
import EventBus from '@/eventBus';
import Modal from '@/components/Modal.vue';
import Notification from '@/components/Notification.vue';

export default {
  components: {
    Modal,
    Notification,
  },
  data() {
    return {
      modal: null,
      notification: null,
    };
  },
  created() {
    EventBus.$on('modal', (modal) => {
      this.modal = modal;
    });

    EventBus.$on('notification', (notification) => {
      this.notification = notification;
    });
  },
};
</script>
