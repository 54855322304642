import Vue from 'vue';
import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.common';
import VueAuthDriverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer';
import VueAuthDriverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x';
import VueAuthDriverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x';
import { changeLocale } from '@/plugins/i18n';

Vue.use(VueAuth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    auth: {
      request: VueAuthDriverAuthBearer.request,
      response(res) {
        return (res.data || {}).access_token;
      },
    },
    http: VueAuthDriverHttpAxios,
    router: VueAuthDriverRouterVueRouter,
  },
  options: {
    notFoundRedirect: {
      path: '/portal/quotations',
    },
    logoutData: {
      url: 'auth/logout',
      method: 'POST',
      redirect: '/login',
      makeRequest: true,
    },
    fetchData: {
      url: 'auth/profile',
      method: 'GET',
      enabled: true,
    },
    refreshData: {
      url: 'auth/refresh',
      method: 'POST',
      enabled: true,
      interval: 0,
    },
    rolesKey: 'allPermissions',
    stores: ['cookie', 'storage'],
    tokenDefaultKey: 'beijer_portal_auth',
    staySignedInKey: 'beijer_portal_stay_signed_in',
    cookie: {
      Path: '/',
      Domain: null,
      Secure: true,
      Expires: 12096e5,
      SameSite: 'Strict',
      Priority: 'High',
    },
    parseUserData(data) {
      changeLocale(data.language);

      return data;
    },
  },
});
