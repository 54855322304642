import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/routes';
import { changeLocale } from '@/plugins/i18n';

Vue.use(VueRouter);

// Initialize router
const router = new VueRouter({
  mode: 'history',
  base: process.env.APP_URL,
  routes,
});

// Set global router for use with VueAuth
Vue.router = router;

// Change locale when "lang" query string is set
router.beforeEach((to, from, next) => {
  if (to.query.lang) {
    changeLocale(to.query.lang);
  }
  next();
});

export default router;
